.logo-sm {
    width: 150px;
}

.logo-md {
    width: 50%;
}

a {
    color: #534173;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1 {
    color: #DC7215;
    font-weight: 600;
    font-size: 30px;
    padding: 0;
    margin: 0;
}

p {
    color: #171717;
    font-size: 14px;
}

b, strong {
    color: #000 !important;
}

a.logout {
    top: -1px;
    position: relative;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.height-100vh {
    height: 100vh;
}

/* Panels */
/* 2 Column Page Layout */
.left-panel {
    width: 50%;
    min-height: 100vh;
    background: linear-gradient(161.93deg, #F08522 9.64%, #FBCF67 63.54%);
}

.right-panel {
    margin: auto;
    width: 27.5%;
}

.user-panel {
    margin: 0 auto;
    margin-top: 100px;
    width: 50%;
}

.tag-line {
    color: rgb(203, 153, 0);
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}

.grid-frame {
    background: #fff;
    border: 1px solid #ccc;
}

/* 2 Column Page Layout */

/* Inside Pages */
.page-container {
    width: 100%;
}

.body-content {
    margin: 125px 0 50px 65px;
}

.body-panel {
    margin-right: 50px;
}

.filter-container {
    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: auto;
    min-width: 25%;
    margin-left: 10px;
    margin-right: 40px;
    padding: 12px 15px;
}

.filter-container-inside {
    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 25%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 10px;
}

.body-container {
    min-width: 70%;
    width: auto;
}

.header-logo {
    height: 100%;
    width: 150px;
    margin-left: 10px;
}

.no-filterpanel {
    margin-left: 100px;
}

.width-50 {
    width: 50%;
}

.width-100 {
    width: 100% !important;
}

/* Inside Pages */

/* User Testimonials */
.user-testimonials {
    margin-top: 17.5%;
}

.user-testimonials-text-title {
    color: #101828;
    font-size: 17px;
    font-weight: 600;
}

.user-testimonials-text {
    color: #101828;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 4;   /* Limits to 4 lines when not expanded */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 0.3s ease; /* Smooth transition */
    max-height: 80px; /* 4 lines * 20px line-height */
}

.user-testimonials-text.expanded {
    -webkit-line-clamp: unset; /* Removes the line clamp */
    display: block;
    overflow: visible;
    max-height: none; /* Allows full height */
}

.user-testimonials-small-text {
    font-size: 13px;
    font-weight: normal;
    display: block;
}

.user-rating-icon {
    color: #534173;
}

.quote-default {
    color: rgba(255, 255, 255, 0.32);
    font-size: 100px;
    position: absolute;
}

.quote-start {
    margin-top: -5%;
}

.quote-end {
    margin-left: 20%;
}

/* User Testimonials */

/* Typography */
.main-heading {
    margin-top: 50px;
    font-size: 25px;
    color: #534173;
    font-weight: 600;
}

.sub-heading {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 600;
}

.subsmall-heading {
    margin-top: 0px;
    font-size: 17px;
    font-weight: 600;
}

.regular-text {
    font-size: 13px;
    color: #534173;
}

.small-text {
    font-size: 11px;
    color: #534173;
}

.text-20px {
    font-size: 20px;
}

.modal-subheading {
    font-size: 17px !important;
    font-weight: 600;
}

.text-hd2 {
    font-size: 14px;
    font-weight: 600;
}

/* Typography */

/* Miscellaneous */
.sep {
    height: 1px;
    background: #98A2B3;
    margin: 15px 0;
}

.social-icon {
    margin: 0 auto;
    border: 0;
    background: transparent;
}

.feature-panel {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    font-size: 14px;
    color: #373737;
    padding: 25px 20px;
    margin: 30px 0;
}

.feature-panel-content {
    border-left: 2px solid #EEB20D;
    padding-left: 20px;
}

.footer-link-container {
    margin: 25px 0;
}

.clearfix {
    clear: both;
}

/* Miscellaneous */

/* Form Elements */
.form-group {
    margin-bottom: 20px;
}

label {
    font-size: 13px;
}

.required {
    color: #D92D20;
    font-size: 12px;
}

.is-invalid {
    border-color: #D92D20;
  }

.form-control,
.form-select {
    border: 1px solid #98A2B3;
    color: #000;
    font-size: 14px;
}

.form-settings {
    color: #344054;
    font-weight: 600;
    font-size: 13px;
}

.btn {
    font-size: 13px !important;
}

.btn-primary {
    background: #DC7215;
    box-shadow: 0px 0.831842px 1.66368px rgba(16, 24, 40, 0.05);
    border-radius: 6.65474px;
    border-color: #DC7215;
    width: 100%;
}

.btn:disabled {
    color: #AAA;
    border-color: #ddd;
    background-color: inherit;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited,
.btn:first-child:active,
.btn:focus-visible {
    background: #534173;
    border-color: #534173;
}

.btn-primary-regular {
    background: #DC7215;
    box-shadow: 0px 0.831842px 1.66368px rgba(16, 24, 40, 0.05);
    border-radius: 6.65474px;
    border-color: #DC7215;
    color: #fff;
}

.btn-primary-regular:hover,
.btn-primary-regular:active,
.btn-primary-regular:focus,
.btn-primary-regular:visited,
.btn:first-child:active,
.btn:focus-visible {
    background: #534173;
    border-color: #534173;
    color: #fff;
}

.btn-secondary {
    background: transparent;
    box-shadow: 0px 0.831842px 1.66368px rgba(16, 24, 40, 0.05);
    border-radius: 6.65474px;
    border: 1px solid #667085;
    color: #DC7215;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:visited,
.btn:first-child:active,
.btn:focus-visible {
    background: #534173;
    border-color: #534173;
}

.btn-nostyle {
    padding: 0;
    background: transparent;
    border: transparent;
}

.btn-nostyle-white {
    color: #fff;
    text-decoration: underline;
}
.btn-nostyle-white:hover {
    text-decoration: none;
}

.btn-link {
    padding: 0;
    background: transparent;
    border: transparent;
    font-size: 12px;
}


.btn-sm {
    font-size: 13px;
}

.link-btn-sm {
    animation: color-change 2s infinite;
    color: #fff;
}

@keyframes color-change {
    0% { color: #dc7215; }
    50% { color: #534173; }
    100% { color: #dc7215; }
}

.blinking-btn, .btn-blinking-btn {
    animation: color-change 2s infinite;
    color: #fff;
}

@keyframes color-change {
    0% { background: #dc7215; }
    25% { background: #534173; }
    50% { background: #dc7215; }
    75% { background: #534173; }
    100% { background: #dc7215; }
}

.form-control:focus,
.form-select:focus {
    border: 1px solid #FBA33C;
    box-shadow: 0px 0px 0px 4px rgba(251, 163, 60, 0.56);
    background: #fff;
}

small {
    font-size: 11px;
    font-style: italic;
    top: -5px;
    position: relative;
    color: #ccc;
}

input[type=range] {
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #534173;
}

input[type=range]::-webkit-slider-thumb {
    background: #fba33c;
}

input[type=range]:focus {
    outline: none;
}

.countryCode {
    width: 10vh;
    max-width: 140px;
    min-width: 120px;
}


/* Form Elements */

/* Inside Header */
header {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    margin-left: 65px;
}

ul.header-navigation {
    margin: 0;
    padding: 0;
}

.header-navigation li {
    list-style-type: none;
    float: left;
    margin-left: 10px;
    font-size: 16px;
}

.header-navigation li i.fa-arrow-right-from-bracket {
    padding-top: 7px;
}

.user-avatar {
    border-radius: 50%;
    width: 180px;
    height: 180px;
}

/* Inside Header */

/* Icon Hover Effect */
.hovicon {
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    text-decoration: none;
    z-index: 1;
    color: #fff;
    top: -2px;
}

.hovicon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.hovicon:before {
    -webkit-font-smoothing: antialiased;
}

.hovicon.effect-8 {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
    -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
    transition: transform ease-out 0.1s, background 0.2s;
}

.hovicon.effect-8:after {
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    opacity: 0;
}

.hovicon.effect-8:hover {
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
}

.hovicon.effect-8:hover i {
    color: #fff;
}

.hovicon.effect-8:hover:after {
    -webkit-animation: sonarEffect 1.3s ease-out 75ms;
    -moz-animation: sonarEffect 1.3s ease-out 75ms;
    animation: sonarEffect 1.3s ease-out 75ms;
}

@-webkit-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
        -webkit-transform: scale(1.5);
        opacity: 0;
    }
}

@-moz-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
        -moz-transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #e9b544, 0 0 0 10px rgba(255, 255, 255, 0.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Icon Hover Effect */

/* Grid & List View */
.grid-container .list-detail li {
    list-style-type: none;
    font-size: 14px;
}

.grid-container .list li.main-list {
    width: 100%;
    border-bottom: 1px solid #CCC;
    margin-bottom: 10px;
    padding-bottom: 10px;
    list-style-type: none;

}

.grid-container .list li.main-list .list-detail li:first-child {
    font-weight: 600;
}

.grid-container .list li.main-list:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.grid-container .grid li.main-list {
    float: left;
    width: 49%;
    margin: 0 0 20px 0;
    border: 1px solid #D0D5DD;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    list-style-type: none;
    background: #fff;
}

.grid-container .grid,
.grid-container .list,
.grid-container .list-detail {
    margin: 0;
    padding: 0;
}

.grid-container .list .list-detail {
    display: flex;
    justify-content: space-between;
}

.grid-container .grid .list-detail {
    display: block;
}

.grid-container .grid .list-detail li {
    width: 50%;
    float: left;
}

.grid-container .grid .list-detail li:first-child {
    width: 100%;
    font-size: 20px;
    color: #101828;
    font-weight: 600;
    border-bottom: 1px solid #D0D5DD;
    padding-bottom: 10px;
    padding-top: 0;
    display: flex;
}

.grid-container .grid .list-detail li:last-child a {
    color: #FBA33C;
}

.grid-container .list.grid-list {
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px !important;
}

.grid-container .grid-list {
    position: relative;
    overflow: hidden;
}

.grid-container .buttons {
    text-align: right;
}

.grid-container .buttons button {
    background: #fff;
    border: 1px solid #A9A9A9;
    padding: 0 10px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    font-size: 20px;
    margin-left: 5px;
}

.list-header {
    background: #E1E7EE;
    border-radius: 5px 5px 0px 0px;
}

ul.list-detail.list-header {
    padding: 10px 20px;
}

ul.grid-list.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-detail a {
    font-size: 14px;
    color: #534173;
    text-decoration: underline;
    font-weight: 400;
}

.list-detail {
    display: flex;
    justify-content: space-between;
}

.list-width-10 {
    width: 10%;
    padding: 0 5px 0 0;
}

.list-width-15 {
    width: 15%;
    padding: 0 5px 0 0;
}

.list-text-label {
    color: #475467;
    font-size: 13px;
    display: inline;
}

.uni-location {
    color: #667085;
    font-size: 13px;
    top: -10px;
    position: relative;
}

span.uni-logo {
    margin-right: 10px;
    display: inline-block;
    background: #AAA;
}

.uni-logo img {
    height: 72px;
    width: 72px;
    object-fit: cover;
}

.loan-logo img {
    height: 50px;
    width: auto;
    object-fit: cover;
}

.grid-container .list-detail.grid li {
    padding-top: 10px;
}

.grid-size {
    display: flex;
    justify-content: end;
    padding: 20px 0;
}

select#pageSizeSelect {
    margin-left: 10px;
}

.grid-contols {
    display: flex;
    justify-content: space-between;
}

.grid-contols {
    display: flex;
    justify-content: space-between;
}

.grid-contols button {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    color: #1D2939;
    font-size: 13px;
    padding: 5px 10px;
}

.grid-contols button:disabled {
    background: #efefef;
}

/* Grid & List View */

/* Scholarship Details */
.scholarship-details-banner {
    width: -webkit-fill-available;
    height: 475px;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    margin-top: -25px;
}

.scholarship-details-banner img {
    width: 100%;
}

.scholarship-details-list ul {
    margin: 0;
    padding: 0 5px;
}

.scholarship-details-list li {
    list-style-type: none;
    float: left;
}

.scholarship-details-content {
    padding: 0 25px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.18);
    width: 50%;
    margin-top: 150px;
}

.scholarship-logo img {
    height: 180px;
    width: 180px;
    object-fit: cover;
}

.scholarship-details-list {
    margin-bottom: 25px;
}

.scholarship-details-list-heading {
    font-size: 14px;
    color: #344054;
}

.scholarship-details-content .accordion-body {
    font-size: 14px;
}

.scholarship-details-content .accordion-body table {
    border: 1px solid #D0D5DD;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.scholarship-logo {
    margin: 25px 0;
    background: #AAA;
}

.scholarship-name {
    color: #DC7215;
    font-size: 25px;
    padding: 50px 0;
}

.link-transcript {
    color: #DC7215;
    font-size: 14px;
    text-decoration: underline;
}

.sort-icon {
    padding: 0 0 0 5px;
}

.scholarship-card {
    border: 1px solid #eee;
    padding: 10px;
    background: #fff;
    float: left;
    text-align: center;
    width: min-content;
    margin-right: 15px;
    margin-bottom: 15px;
}

.scholarship-card-count {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 600;
}

/* Scholarship Details */

/* Filter Panel */
.filter-container-item label {
    font-size: 13px;
    color: #101828;
}

.filter-container-item.form-group {
    margin-bottom: 10px;
}

.sidepanel-heading {
    font-size: 22px;
    font-weight: 400;
    color: #101828;
}

.link-filter {
    color: #DC7215;
    font-size: 14px;
    float: right;
    text-decoration: underline;
}

.applied-filter {
    background: rgba(251, 163, 60, 0.18);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;
    display: inline-block;
}

.applied-filter i {
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.applied-filter:hover {
    text-decoration: none;
}

i.fa.fa-info-circle {
    margin-left: 5px;
}

.filter-close-btn {
    cursor: pointer;
    float: right;
    position: relative;
    top: 5px;
    color: #DC7215;
}

/* Filter Panel */

/* Toggle  Password */
button#toggle-password {
    margin-top: -25px;
    height: 15px;
    border: 0;
    float: right;
    margin-right: 10px;
    background: transparent;
}

.input-password[type=password]:valid {
    background: url("../Media/Images/eye_open.svg") 98% no-repeat !important;
}

.input-password[type=text]:valid {
    background: url("../Media/Images/eye_cross.svg") 98% no-repeat !important;
}

/* Toggle Password */

/* Category Panel */
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    color: #FBA33C;
    font-weight: 600;
    text-decoration: underline;
}

.nav-pills .nav-link {
    color: #667085;
    padding-left: 0;
    padding-right: 30px;
}

/* Category Panel */

/* Footer Links */
.footer-links {
    margin: 20px 50px 0 0;
    text-align: right;
}

.footer-links ol li {
    margin-bottom: 10px;
}

.footer-btn {
    background: transparent;
    border: 0;
    font-size: 14px;
    color: #AAA;
}

.modal-body,
.modal-body p {
    text-align: left;
    font-size: 12px;
}

/* Footer Links */

/* Loan */
.emiloanframe {
    min-height: 750px;
    width: 100%;
}

.sub-section .link-btn {
    background: transparent;
    color: #DC7215;
    padding: 0;
    border: 0;
    text-decoration: underline;
    font-weight: 600;
}

/* File Upload */
.no-padding {
    padding: 0 !important;
}

.page-filter {
    border-radius: 10px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 20px;
}

.file-list {
    background: #fff;
    font-size: 13px;
    border: 1px solid #D0D5DD;
}

.file-mandatory:before {
    content: "required";
    rotate: 270deg;
    display: inline-block;
    font-size: 9px;
    position: relative;
    left: -20px;
    top: 2px;
    background: #FBA33C;
    color: #fff;
    padding: 4px;
}

.file-view {
    border: 1px solid #D0D5DD;
    background: #fff;
    float: left;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
    margin-right: 10px;
}

.file-controls {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid #ddd;
}

.file-controls li {
    float: left;
    padding: 5px;
}
.file-underreview {
    border: 2px solid #ccc;
}
.file-underreview:before {
    content: "under review";
    display: inline-block;
    font-size: 9px;
    position: relative;
    left: -26px;
    top: 15px;
    background: #ccc;
    color: #000;
    padding: 4px;
    margin-right: -45px;
    display: none;
}
.file-approved {
    border: 2px solid #32D583;
}
.file-approved:before {
    content: "approved";
    rotate: 270deg;
    display: inline-block;
    font-size: 9px;
    position: relative;
    left: -27px;
    top: 15px;
    background: #32D583;
    color: #fff;
    padding: 4px;
    margin-right: -30px;
    width: 65px;
    text-align: center;
    display: none;
}
.file-rejected {
    border: 2px solid #F04438;
}
.file-rejected:before {
    content: "rejected";
    rotate: 270deg;
    display: inline-block;
    font-size: 9px;
    position: relative;
    left: -27px;
    top: 15px;
    background: #F04438;
    color: #fff;
    padding: 4px;
    margin-right: -30px;
    width: 65px;
    text-align: center;
    display: none;
}

.file-uploaded button.accordion-button {
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
}

.file-uploaded .accordion-body {
    overflow: hidden;
}

button.accordion.accordion-button {
    width: auto;
    position: relative;
    right: 0;
    padding: 0;
    margin: 0;
    float: right;
    background: transparent;
}

.accordion-button:not(.collapsed)::after {
    border-top: 1px solid #fff;
}

.file-underreviewbg {
    background: #ccc;
    color: #000;
    display: inline-block;
    width: auto;
    padding: 2px 5px;
    text-align: center;
    margin-right: 5px;
}

.file-approvedbg {
    background: #32D583;
    color: #fff;
    display: inline-block;
    width: auto;
    padding: 2px 5px;
    text-align: center;
    margin-right: 5px;
}

.file-rejectedbg {
    background: #F04438;
    color: #fff;
    display: inline-block;
    width: auto;
    padding: 2px 5px;
    text-align: center;
    margin-right: 5px;
}

a.widget-cta-page-nopos.btn-request {
    float: right;
    font-weight: normal;
    position: relative;
}

/* File Upload */


/* Counseling */
.timeline-carousel {
    margin: 0;
    font-weight: 400;
    padding: 0 0 40px 2.5%;
    position: relative;
    overflow: hidden;
}

.timeline-carousel:after,
.timeline-carousel:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 6.9444%;
    z-index: 3;
    width: 6.9444%;
}

.timeline-carousel:after {
    left: 0;
}

.timeline-carousel:before {
    right: 0;
    opacity: 0;
}

.timeline-carousel .slick-list {
    overflow: visible;
}

.timeline-carousel .slick-dots {
    bottom: -40px;
}

.timeline-carousel h1 {
    font-weight: 700;
}

.timeline-carousel p {
    font-weight: 300;
}

.timeline-carousel__item {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.timeline-carousel__item .media-wrapper {
    opacity: 0.4;
    padding-bottom: 50%;
    -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.timeline-carousel__item:last-child .timeline-carousel__item-inner:after {
    width: calc(100% - 30px);
}

.timeline-carousel__item-inner:after {
    position: absolute;
    width: 100%;
    top: 45px;
    left: 0;
    content: "";
}

.timeline-carousel__item-inner .year {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: block;
    color: #101828;
    margin: 10px;
}

.timeline-carousel__item-inner .year:after {
    content: "";
    position: absolute;
    display: block;
    left: -10px;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: 3;
}

.timeline-carousel__item-inner .country-info {
    font-size: 12px;
    color: #101828;
    display: block;
}

.timeline-carousel__item-inner .country-info ul {
    margin: 0;
    padding: 0;
}

.timeline-carousel__item-inner .country-info ul li {
    list-style-type: none;
    padding: 5px 0;
    text-align: center;
}

@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.pie {
    --p: 20;
    --b: 5px;
    --c: darkred;
    --w: 50px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px 0;
    place-content: center;
    font-size: 9px;
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background:
        radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b)/2);
    background: var(--c);
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate {
    animation: p 1s .5s both;
}

.no-round:before {
    background-size: 0 0, auto;
}

.no-round:after {
    content: none;
}

@keyframes p {
    from {
        --p: 0
    }
}

.fa-money-bill-wave {
    color: #534173;
}

@-webkit-keyframes animate-width {
    0% {
        width: 0;
    }

    100% {
        visibility: visible;
    }
}

@-moz-keyframes animate-width {
    0% {
        width: 0;
    }

    100% {
        visibility: visible;
    }
}

@keyframes animate-width {
    0% {
        width: 0;
    }

    100% {
        visibility: visible;
    }
}

@-webkit-keyframes animate-height {
    0% {
        height: 0;
    }

    100% {
        visibility: visible;
    }
}

@-moz-keyframes animate-height {
    0% {
        height: 0;
    }

    100% {
        visibility: visible;
    }
}

@keyframes animate-height {
    0% {
        height: 0;
    }

    100% {
        visibility: visible;
    }
}

.country-info p {
    font-size: 12px;
    margin-bottom: 0;
    padding: 2px 0;
    border-radius: 5px;
    text-align: center;
    background: #eee;
}

.timeline-carousel__item-inner .read-more {
    font-size: 12px;
    color: #ffc107;
    display: table;
    margin-bottom: 10px;
    font-weight: 900;
    text-decoration: none;
    position: relative;
}

.timeline-carousel__item-inner .read-more:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    border-bottom: 2px solid #ffc107;
    -webkit-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    -o-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.timeline-carousel__item-inner .read-more:hover:after {
    width: 100%;
}

.timeline-carousel__item-inner .pointer {
    height: 29px;
    position: relative;
    z-index: 1;
    margin: -4px 0 16px;
}

.timeline-carousel__item-inner .pointer:after,
.timeline-carousel__item-inner .pointer:before {
    position: absolute;
    content: "";
}

.timeline-carousel__item-inner .pointer:after {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    top: 0;
    left: 0;
    background-color: #ffc107;
}

.timeline-carousel__item-inner .pointer:before {
    width: 1px;
    height: 100%;
    top: 0;
    left: 4px;
    background-color: #ffc107;
}

.timeline-carousel .slick-active .media-wrapper {
    opacity: 1 !important;
    border-radius: 10px;
    border: 1px solid #D0D5DD;
}

.slick-track {
    display: flex !important;
}

.slick-dots {
    bottom: 160px;
    list-style: none;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    z-index: 2;
}

.slick-dots li {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    display: inline-block;
    margin: 0 5px;
    position: relative;
    width: 10px;
    height: 10px;
}

.slick-dots li:last-child {
    margin-right: 0;
}

.slick-dots li.slick-active button {
    background: #ffc107;
    border-color: #ffc107;
}

.slick-dots li button {
    display: block;
    font-size: 0;
    width: 10px;
    height: 10px;
    padding: 0;
    background-color: #101828;
    cursor: pointer;
    -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    border-radius: 50%;
}

.link {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    z-index: 9999;
}

.link a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.link .fa {
    font-size: 28px;
    margin-right: 8px;
    color: #fff;
}

.slick-prev svg,
.slick-next svg {
    height: auto;
    width: 18px;
    padding-bottom: 2px;
}

.slick-prev .btn,
.slick-next .btn {
    font-size: 12px;
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 40%;
    z-index: 9999;
    border-radius: 0;
    font-size: 30px;
    cursor: pointer;
}

.slick-next {
    right: 0px;
}

.slick-prev {
    left: -20px;
}

.slick-slide {
    margin-right: 20px;
}

#countryCounselingModal .modal-header,
#courseCounselingModal .modal-header {
    background: #F2F4F7;
}

#countryCounselingModal .modal-title,
#courseCounselingModal .modal-title {
    font-size: 24px;
    color: #475467;
}

#countryCounselingModal .modal-dialog.modal-xl {
    margin: 0;
}

.country-selection {
    display: block;
    text-align: center;
}

.country-selected {
    border: 1px solid rgba(251, 163, 60, 0.74);
    box-shadow: 2px 4px 8px 0px rgba(251, 163, 60, 0.74);
}

.video-frame {
    border-radius: 8px;
    background: #E9E9E9;
    height: 400px;
}

/* Avatar Controls */
.chapters {
    position: absolute;
    top: 10px;
    right: 10px;
}

.chapterBtn {
    float: left;
    clear: both;
    background: rgba(255, 255, 255, 0.5);
    border: 0;
    padding: 2px 5px;
    font-size: 11px;
    width: 100%;
    border-radius: 5px;
    margin: 2px 5px;
}

.chapterBtn:hover {
    background: rgba(255, 255, 255, 1);
}

.bot-response {
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
}

.bot-response.bot_msg .bot-logo {
    float: right;
    margin-right: 0;
    left: 0;
}

.bot-response p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 15px;
    color: #000;
}

.bot-logo {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #efefef;
    object-fit: cover;
    object-position: 7% 0;
    float: left;
    margin-right: 10px;
    position: relative;
    top: -8px;
    left: -7px;
}

.bot-heading {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}

.bot-responses {
    height: 250px;
    overflow-y: auto;
}

.bot-query .btn-link {
    font-size: 12px;
    text-decoration: none;
    padding: 5px;
    margin-top: 10px;
}

.bot-frame {
    border: 1px solid #ccc;
    background: #fff url('../Media/Images/rise-chat.png') center 10px no-repeat;
    background-size: 50%;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.10);
    height: 400px;
    padding: 15px;
}

#wise-calc-widget {
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.tab-content {
    width: 100%;
}

.application-profile .nav-link {
    text-align: left;
}

.application-profile.nav-pills .nav-link.active {
    background: #5B79A1;
}

.application-profile .nav-link {
    color: #5B79A1;
}

.application-profile .nav-link:hover {
    color: #DC7215;
}

.action-btn-group button:first-child {
    margin-right: 5px;
}

.btn-check+label {
    margin-right: 10px;
}

.application-profile.nav-pills .nav-link.active {
    color: #fff;
    text-decoration: none;
    font-weight: normal;
}

.application-profile.nav-pills .nav-link {
    padding-left: 30px;
}

.settings-container {
    background: #fff;
    margin-top: -25px;
    overflow: hidden;
    padding: 50px;
    width: auto;
}

.section-head {
    background: #f6f6f6;
    padding: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.section-head svg.bi {
    top: 4px;
    position: relative;
}

.section-content {
    padding: 10px;
    border: 1px solid #f6f6f6;
}

#tidio-chat iframe {
    top: 250px !important;
}

.widget-cta-page {
    background: #DC7215;
    color: #fff;
    border-radius: 10px;
    border: 0;
    font-size: 13px;
    padding: 10px;
    position: absolute;
    right: 20px;
    margin-top: 20px;
}

.widget-cta-page-nopos {
    background: #DC7215;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-size: 13px;
    padding: 7px;
}

.widget-cta-page-nopos-noresponsive {
    background: #DC7215;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-size: 13px;
    padding: 7px;
}

.course-alt {
    background: #F5F6F8;
    font-size: 17px;
    padding: 30px 10px;
    max-width: 800px;
    width: auto;
    margin: 0 auto;
    margin-top: -150px;
    padding-bottom: 70px;
    color: #286EAF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.course-alt-or {
    text-align: center;
    font-weight: 600;
    margin-top: -25px;
    padding-bottom: 25px;
}

.btn-profileab {
    position: relative;
    z-index: 9999;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #0060EB;
    border-radius: 4px;
    padding: 8px 16px;
    text-transform: uppercase;
    border: 1px solid #A4ACC2;
    margin-top: 10px;
}

.text-black {
    color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

.guide-link img {
    width: 30px;
}

.panel {
    display: none;
}

.panel.visible {
    display: block;
    animation: fade_in_show 1s
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.video-container {
    text-align: right;
}

.btn-hidefilter {
    background: transparent;
    border: 0;
    font-size: 13px;
    margin-left: 20px;
}

.btn-showfilter {
    background: transparent;
    border: 0;
    font-size: 13px;
    padding-left: 0;
}

.loading-bg {
    background: url('../Media/Images/loading.gif') center 150px no-repeat;
    background-size: 50px;
}

.user-image-thumb {
    border: 1px solid #dc7215;
    border-radius: 50%;
    padding: 10px;
    width: 100px;
    height: 100px;
    text-align: center;
}

.user-image-thumb i {
    font-size: 70px;
    color: #dc7215;
}

.accommodation-card h2 {
    font-size: 15px;
    font-weight: 600;
}

.info-text {
    margin: 0;
    padding: 0;
    font-style: italic;
}

.text-legends {
    display: none;
}

.image-gallery-thumbnail-inner {
    background: url('../Media/Images/loading.gif') center center no-repeat;
    min-height: 20px;
    background-size: 20px 20px !important;
}

.grid-frame {
    background: #fff;
    border: 1px solid #ccc;
}

.shortlisting-data-table-frame {
    height: 550px;
    margin-left: 10px;
    padding-left: 0;
    padding-right: 0;
}

.shortlisting-data-table {
    font-size: 13px;
}

span.text-elipsis {
    word-wrap: break-word;
    width: 100px;
}

.user-menu {
    margin: 0;
    padding: 0;
}

.user-menu li {
    list-style-type: none;
    float: left;
    margin-right: 5px;
    font-size: 11px;
    border-right: 1px solid #aaa;
    padding-right: 5px;
    line-height: normal;
    cursor: pointer;
    color: #DC7215;
    margin-top: 5px;
}

.user-menu li:last-child {
    border-right: 0;
}

.credentials-list {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 13px;
}

.credentials-list li {
    list-style-type: none;
}

.app-multiSelect .css-1nmdiq5-menu {
    position:static;
}

.notification-grid-container {
    height: 500px;
    overflow-y: auto;
    margin-top: 20px;
}

.notification-text {
    margin: 10px 0 10px 10px;
}

.notification-item {
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
}

.notification-item:last-child {
    border-bottom: 0;
}

.notification-item p {
    font-size: 14px;
    width: 80%;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: auto;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: none;
}

.nav-item {
    margin-bottom: -1px;
}

.notification-btngroup {
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
}

.e-control,
.e-css {
    font-family: 'Open Sans' !important;
}

.e-control {
    font-size: initial !important;
}

.e-control:focus,
.e-control *:focus {
    outline: inherit;
}

.react-autosuggest__container:before {
    font-family: "FontAwesome";
    content: "\f002";
    position: absolute;
    right: 0;
    font-size: 16px;
    margin-right: 10px;
    margin-top: 2px;
    color: #444;
}

.list-scroll {
    max-height: 500px;
    overflow-y: auto;
}

.chat-icon {
    min-width: 25%;
    padding: 10px;
    z-index: 9999;
}

.chat-icon-container {
    background: #DC7215;
    padding: 20px;
    border-radius: 50%;
    border: 2px solid #fff
}

.course-list-item {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 48%;
    margin: 0 20px 20px 0;
    height: fit-content;
}

.course-thumbnail {
    width: 50%;
    height: 50%;
}

.app-multiSelect {
    font-size: 14px;
}

input.react-autosuggest__input:disabled {
    background: #eee;
}

.react-joyride__tooltip button[data-test-id="button-primary"] {
    background: #dc7215 !important;
}

.template-list {
    margin-right: 10px;
    max-height: 1000px;
    overflow-y: auto;
    background: #fff;
}

.template-list .template {
    background: rgb(255, 255, 255);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}

.marketing-email-content {
    width: 65%;
}

.course-form {
    gap: 1rem;
    width: 100%;
}

.chapters-list {
    width: 40%;
    margin-left: 50px;
}

.file-upload {
    height: 35px;
}

.file-name {
    word-break: break-all;
}

.custom-textarea {
    min-height: 100px !important;
}

.step-navtext {
    margin-top: 10px;
    font-size: 20px;
    color: #ccc;
    margin-bottom: 10px;
    text-align: center;
}

.fees-switch {
    margin-top: 20px;
    font-size: 14px;
}

.fees-switch .switch {
    margin: 0 10px;
    width: 70px;
    height: 25px;
    top: -3px;
}

.fees-switch .slider {
    border-radius: 10px;
}

.fees-switch .slider:before {
    border-radius: 20px;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 3px;
    background-color: white;
    transition: .5s;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: auto;
}

.shortlisting-results ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.shortlisting-results li {
    list-style-type: none;
}

.shortlisting-record {
    border-radius: 10px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 10px;
    margin: 10px 10px 10px 0;
    display: flow-root;
}

.shortlisting-name {
    font-size: 20px;
    padding-top: 0 !important;
    padding-bottom: 10px !important;
}

.shortlisting-name p {
    margin-bottom: 0;
}

.shortlisting-name span:first-child {
    max-width: 90%;
    display: inline-block;
}

.shortlisting-name span.fav {
    float: right;
}

.shortlisting-record span.fav:hover .fa::before {
    color: #dc7215;
    content: "\f02e";
    cursor: pointer;
}

.shortlisting-record .fa-bookmark {
    color: #dc7215;
}

.shortlisting-record .tags {
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.tag-pouplar {
    background: #e5def7;
    color: #5211ea;
}

.tag-highjobdemand {
    background: #e0f7de;
    color: #097518;
}

.tag-value {
    color: #097518;
    font-size: 13px;
    margin-left: 10px;
}

.sort-by {
    width: 30%;
    text-align: right;
}

.btn-mainnav {
    color: #fff;
    font-size: 17px;
    width: 100%;
    text-align: left;
    padding: 12px 20px 13px 10px;
}

.btn-mainnav:hover {
    color: #ffffff;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    text-decoration: none;
}
.vertical-steps {
    list-style: none;
    padding: 0;
    margin: 0;
}
.vertical-steps li {
    padding-bottom: 20px;
    padding-left: 30px;
    font-size: 14px;
    position: relative;
}
.vertical-steps p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 23px;
}
.vertical-steps li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px dashed #d3d3d3;
    background-color: white;
}
.vertical-steps li::after {
    content: '';
    position: absolute;
    left: 9px;
    top: 23px;
    width: 2px;
    height: calc(100% - 20px);
    background-color: #d3d3d3;
}
.vertical-steps li.active::before {
    border: 2px solid #aaa;
    background-color: white;
}
.vertical-steps li.active::after {
    background-color: #aaa;
}
.vertical-steps li.complete::before {
    border-color: green;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical-steps li.complete::before {
    content: '\f00c';
    /* Font Awesome checkmark icon */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: white;
}
.vertical-steps li.complete::after {
    background-color: green;
}
.vertical-steps li:last-child::after {
    display: none;
}
.vertical-steps li span {
    display: none;
}
.vertical-steps li button.btn-primary-steps {
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 11px;
}
.btn-primary-steps:hover {
    background: #ccc;
}
.primary-steps-link {
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 11px;
    padding: 6px;
    top: 1px;
    position: relative;
    text-decoration: none;
    margin-right: 10px;
    background: #fff;
}
.primary-steps-link:hover {
    background: #dc7215;
    transition: .5s;
    color: #fff;
    text-decoration: none;
}
.btn-primary-steps-link {
    color: #534173;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none; 
}
.text-error {
    font-size: 11px;
    color: #D92D20;
}
.border-error {
    border-color: #D92D20;
}
.basic-plan {
    margin-top: 50px;
}
.time-limit {
    font-size: 18px;
    color: #dc7215;
}
.profile-form {
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(53,105,128,.3),0 6px 20px 0 rgba(165,200,213,.41);
    padding: 20px;
}
.profile-form label {
    margin-left: 10px;
}
ul.schedule-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
ul.schedule-list li {
    display: inline-block;
    margin-right: 20px;
    vertical-align: bottom;
}
ul.schedule-list li p {
    margin-bottom: 0;
}
.rdtPicker {
    font-size: 13px;
}

/* Container for the loading bar */
.loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10px;
    left: 75%;
    width: 25%;
}
  
.loading-bar {
    width: 80%;          /* Width of the loading bar container */
    height: 5px;        /* Height of the loading bar */
    background-color: #e0e0e0; /* Gray background */
    border-radius: 5px;
    overflow: hidden;
}
  
.loading-progress {
    height: 100%;
    background-color: #534173; /* Blue color for the loading bar */
    transition: width 0.1s ease-in-out; /* Smooth transition effect */
}

.blinking-bulb {
    display: inline-block;
    color: #f5c518; /* Yellow color */
    animation: blink 1.5s infinite; /* Animation for blinking */
    margin-right: 10px; /* Spacing between the bulb and text */
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.video-frame-step {
    height: 200px;
}

.radio-option {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;
}

input.form-control.basicprofile-form-control {
    background: transparent;
    border: 0;
    border-bottom: 2px solid #000;
    border-radius: 0;
}

.basicprofile-form .page-heading {
    font-size: 25px;
}

.subscription-plan-name {
    font-size: 13px;
    font-weight: 600;
}

.reject-message {
    border-top: .25px solid #D92D20;
    margin-top: 5px;
    padding-top: 5px;
}

.active-plan {
    border: 10px solid #ca9900 !important;
}

.form-customselect {
    font-size: 14px;
    color: #000;
}